<template>
  <div class="orderManagement">
    <AddressManage @changeGroup="changeGroup" />
    <!-- 收货信息表头 -->
    <h3 class="confirmOrderTitle">
      确认订单信息
    </h3>
    <div class="tableHeader">
      <span class="merchandiseNews">商品信息</span>
      <span class="specification">规格</span>
      <span class="unitPrice">含税单价(元/吨)</span>
      <span class="weight">重量(吨)</span>
      <span class="subtotal">小计金额(元)</span>
    </div>
    <!-- 订单商品 -->
    <div class="orderGoods">
      <p class="companyName">
        <img src="@/assets/img/dianpu.png" alt="店铺">{{ orderDetails.goodsDetailInfoDto.cmpName }}
      </p>
      <!-- 商品信息 -->
      <div class="commodityInformationBox">
        <div class="commodityInformation">
          <img
            class="commodityImage"
            :src="orderDetails.goodsDetailInfoDto && orderDetails.goodsDetailInfoDto.productMainUrl && orderDetails.goodsDetailInfoDto.productMainUrl.indexOf('http') >= 0 ? orderDetails.goodsDetailInfoDto.productMainUrl : $fileUrl + orderDetails.goodsDetailInfoDto.productMainUrl"
            alt="商品图片"
          >
          <h3 class="title">
            {{ orderDetails.goodsDetailInfoDto.goodsTwoCategoryName }} {{ orderDetails.goodsDetailInfoDto.goodsMaterialName }}
            <br><span>{{ orderDetails.goodsDetailInfoDto.goodsOneCategoryName }}</span>
          </h3>
          <div class="detail">
            <span>钢厂/厂家：{{ orderDetails.goodsDetailInfoDto.manufactor }}</span>
            <span>规格：{{ orderDetails.goodsDetailInfoDto.goodsSpecName }}</span>
            <span class="elp-1">发货地：{{ orderDetails.goodsDetailInfoDto.placeProvince }}{{ orderDetails.goodsDetailInfoDto.placeCity }}{{ orderDetails.goodsDetailInfoDto.placeTown }}{{ orderDetails.goodsDetailInfoDto.placeDetailAddr }}</span>
          </div>
          <p class="price">
            {{ orderDetails.goodsDetailInfoDto.unitPriceIncludingTax }}
          </p>
          <p class="weight">
            {{ numberThreeDecimal(orderDetails.goodsDetailInfoDto.purchaseWeight) }}
          </p>
          <p class="total">
            ￥{{ orderDetails.goodsDetailInfoDto.total }}
          </p>
        </div>
      </div>
    </div>
    <!-- 商家支持账户 -->
    <div class="merchantAccount">
      <h3 class="account">
        选择商家支持账户
      </h3>
      <div class="bankList">
        <div
          v-for="(item, index) in accountListData"
          :key="index"
          class="bank"
          :class="activeSettleBankId === item.settleBankId ? 'active' : ''"
          @click="getActiveIndex(item.settleBankId)"
        >
          <img v-if="activeSettleBankId === item.settleBankId" src="@/assets/img/checked.png" class="checked" alt="选中">
          <img v-if="item.settleBankId === 'ABC'" class="bankImg" src="@/assets/img/big_ABC.png" alt="bangIcon">
          <img v-if="item.settleBankId === 'ICBC'" class="bankImg" src="@/assets/img/big_ICBC.png" alt="bangIcon">
          <img v-if="item.settleBankId === 'LSBANK'" class="bankImg" src="@/assets/img/big_LSBANK.png" alt="bangIcon">
          <p class="elp-1">
            {{ item.settleBankName }}
          </p>
        </div>
      </div>
    </div>
    <!-- 支付信息 -->
    <div class="paymentInformation">
      <h3 class="title">
        支付信息
      </h3>
      <p class="lable">
        <span>支付方式</span>
        <i v-for="pay in payRuleListData" :key="pay.id" :class="['item',formData.tradePayRuleStyleID === pay.id?'active':'']" @click="changePayRule(pay.id)">
          <img v-if="formData.tradePayRuleStyleID === pay.id" src="@/assets/img/checked.png" class="checked" alt="选中">
          {{ pay.payStyle === '00' ? '全款支付' : '保证金支付' }}
        </i>
      </p>
      <p class="lable">
        <span>交收方式</span>{{ payCurrentStyle.settlementStyle === '00' ? '一次交付' : payCurrentStyle.settlementStyle === '01' ? '多次交付' : '' }}
      </p>
      <p v-if="payCurrentStyle.payStyle === '01'" class="lable">
        <span>预付比例(%)</span>{{ payCurrentStyle.bondRate }}%
      </p>
      <p class="lable price">
        <span>{{ payCurrentStyle.payStyle === '01' ? '保证金金额（元）' : '货款金额（元）' }}</span>{{ payCurrentStyle.payStyle === '01' ? orderDetails.goodsDetailInfoDto.total + '*' + payCurrentStyle.bondRate + '%' + '=' + ((orderDetails.goodsDetailInfoDto.total*payCurrentStyle.bondRate)/100).toFixed(2) : orderDetails.goodsDetailInfoDto.total }}
      </p>
    </div>
    <!-- 配送信息 -->
    <div class="paymentInformation marginTop">
      <h3 class="title">
        配送信息
      </h3>
      <p class="lable">
        <span>配送方式</span>
        <i v-show="orderDetails.goodsDetailInfoDto.buyerPickUp === 1" :class="['item',formData.buyerPickUp === 1?'active':'']" @click="getDelivery('buyerPickUp')">
          <img v-if="formData.buyerPickUp === 1" src="@/assets/img/checked.png" class="checked" alt="选中">买家自提</i>
        <i v-show="orderDetails.goodsDetailInfoDto.merchantDistribution === 1" :class="['item',formData.merchantDistribution === 1?'active':'']" @click="getDelivery('merchantDistribution')">
          <img v-if="formData.merchantDistribution === 1" src="@/assets/img/checked.png" class="checked" alt="选中">商家配送</i>
        <i v-show="orderDetails.goodsDetailInfoDto.transfer === 1" :class="['item',formData.transfer === 1?'active':'']" @click="getDelivery('transfer')">
          <img v-if="formData.transfer === 1" src="@/assets/img/checked.png" class="checked" alt="选中">过户</i>
      </p>
      <p v-if="formData.buyerPickUp === 1 || formData.transfer === 1" class="lable">
        <span>自提地址</span>{{ `${orderDetails.goodsDetailInfoDto.placeProvince}${orderDetails.goodsDetailInfoDto.placeCity}${orderDetails.goodsDetailInfoDto.placeTown}${orderDetails.goodsDetailInfoDto.placeDetailAddr} ${orderDetails.goodsDetailInfoDto.listingPerson} ${orderDetails.goodsDetailInfoDto.personPhone}` }}
      </p>
      <p class="lable price">
        <span>运费(元)</span>
        <span v-if="formData.merchantDistribution === 1" class="freight">
          <el-popover
            placement="top-start"
            title="求购联系人"
            width="200"
            trigger="hover"
            :content="orderDetails.goodsDetailInfoDto.listingPerson + ' ' + orderDetails.goodsDetailInfoDto.personPhone"
          >
            <span slot="reference" class="contact-buyer">
              <img src="@/assets/img/contact.png" alt="contact">
              立即咨询</span>
          </el-popover>
          <span class="freight">下单后，商家会录入运费值，买家确认后可支付</span>
        </span>
        <span v-else>0.00</span>
      </p>
    </div>
    <!-- 提交订单 -->
    <div class="confirmOrder">
      <!-- 金额 -->
      <div class="amount">
        <p class="price">
          <span>应付金额：</span>￥{{ payCurrentStyle.payStyle === '01' ? (floatMul(Number(orderDetails.goodsDetailInfoDto.total), Number(payCurrentStyle.bondRate)/100)).toFixed(2) : orderDetails.goodsDetailInfoDto.total }}
        </p>
        <p class="payment">
          <span>货款金额：</span>￥{{ orderDetails.goodsDetailInfoDto.total }} &nbsp;&nbsp;<span v-if="payCurrentStyle.payStyle === '01'">保证金金额：{{ (floatMul(Number(orderDetails.goodsDetailInfoDto.total), Number(payCurrentStyle.bondRate)/100)).toFixed(2) }}</span><span class="freight">运费：</span>{{ formData.merchantDistribution === 1 ? '待确认' : '￥0.00' }}
        </p>
      </div>
      <!-- 按钮组合 -->
      <div class="buttonCombination">
        <router-link :to="{path:'/shoppingCart/index'}" class="back">
          <img src="@/assets/icon/back.png" alt="back"> 返回购物车
        </router-link>
        <el-button class="button" size="small" type="primary" :loading="$store.state.loading" @click="submitForm">
          提交订单
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import AddressManage from './components/addressManage.vue'
import { tradeOrderCartDetail, tradeOrderGoodsDetail, tradeOrderSave } from '@/http/order'
import { fundAccountInfoListSettleBankByCmpId } from '@/http/account'
import { tradePayRuleStyleQuery } from '@/http/payment'
import { numberThreeDecimal, floatMul } from '@/unit/index'
// import {
//   tradeReceivingAddressRemove,
//   tradeReceivingAddressSetting
// } from '@/http/address'

export default {
  components: { AddressManage },
  data() {
    return {
      numberThreeDecimal,
      floatMul,
      activeSettleBankId: null,
      // 操作地址弹窗显影
      dialogVisible: false,
      // 操作地址表单
      addressFormData: {},
      // 地址列表
      addressList: [],
      // 选择当前地址
      addressCurrent: '',
      radio: '',
      // 地址展开
      isMore: false,
      // 订单详情
      orderDetails: {
        goodsDetailInfoDto: {},
        receivingAddressList: []
      },
      // 开户银行列表
      accountListData: [],
      // 支付方式列表
      payRuleListData: [],
      // 当前选择支付方式
      payCurrentStyle: {},
      // 配送方式
      deliveryRuleData: '',
      formData: {
        tradePayRuleStyleID: '', // 支付方式ID
        tradeReceivingAddressId: '', // 收货地址
        buyerPickUp: '',
        merchantDistribution: '',
        transfer: ''
      },
      api: ''
    }
  },
  created() {
    if (this.$route.query.type && this.$route.query.id) {
      const type = this.$route.query.type
      if (type === 'cartBuy') this.api = tradeOrderCartDetail
      if (type === 'goodsBuy') this.api = tradeOrderGoodsDetail
      this.getOrderDetail()
    }
  },
  methods: {
    changeGroup(radio) {
      this.formData.tradeReceivingAddressId = radio
    },
    // 获取商品下单详情
    getOrderDetail() {
      this.api(this.$route.query.id, res => {
        this.orderDetails = { ...res.data }
        const defaultAddress = this.orderDetails.receivingAddressList && this.orderDetails.receivingAddressList.length > 0 ? this.orderDetails.receivingAddressList.find((item) => item.defaultAddressFlag === true) : {}
        if (defaultAddress) this.formData.tradeReceivingAddressId = defaultAddress.id || ''
        this.formData.goodsId = res.data.goodsDetailInfoDto.id
        if (this.$route.query.type === 'cartBuy') {
          this.formData.purchaseWeight = res.data.goodsDetailInfoDto.purchaseWeight
          this.formData.cartId = this.$route.query.id
        }
        if (this.$route.query.type === 'goodsBuy') {
          this.formData.purchaseWeight = this.orderDetails.goodsDetailInfoDto.purchaseWeight = this.$route.query.purchaseWeight
        }
        this.orderDetails.goodsDetailInfoDto.total = (res.data.goodsDetailInfoDto.unitPriceIncludingTax * res.data.goodsDetailInfoDto.purchaseWeight).toFixed(2)
        this.getBankInfo()
        if (this.orderDetails.goodsDetailInfoDto.buyerPickUp === 1) {
          this.getDelivery('buyerPickUp')
          this.deliveryRuleData = 'buyerPickUp'
          return
        }
        if (this.orderDetails.goodsDetailInfoDto.merchantDistribution === 1) {
          this.getDelivery('merchantDistribution')
          this.deliveryRuleData = 'merchantDistribution'
          return
        }
        if (this.orderDetails.goodsDetailInfoDto.transfer === 1) {
          this.getDelivery('transfer')
          this.deliveryRuleData = 'transfer'
          return
        }
      })
    },
    // 点击商户银行卡
    getActiveIndex(index) {
      this.activeSettleBankId = index
      this.getPayRule()
    },
    // 新增、编辑收货地址
    clickAddressManage(item, type) {
      this.dialogVisible = true
      if (!type) return
      this.addressFormData = item
    },
    // 地址管理 设置默认/删除
    // async setAddressManage({ id }, type) {
    //   let tipText = ''
    //   let api = ''
    //   if (type === 'delete') {
    //     tipText = '确认要删除该收货地址吗？'
    //     api = tradeReceivingAddressRemove
    //   }
    //   if (type === 'default') {
    //     tipText = '确认要设为默认收货地址吗？'
    //     api = tradeReceivingAddressSetting
    //   }
    //   const confirm = await this.$confirm(tipText, '提示', {
    //     type: 'warning'
    //   }).catch((err) => err)
    //   if (confirm !== 'confirm') return
    //   api(id, (res) => {
    //     this.$message.success(res.msg || '成功')
    //     this.getAddressList()
    //   })
    // },
    // // 获取所有收货地址
    // getAddressList() {
    //   tradeReceivingAddressList((res) => {
    //     this.addressList = res.data
    //     const defaultAddress = res.data.find((item) => item.defaultAddressFlag === true)
    //     this.formData.tradeReceivingAddressId = defaultAddress.id
    //   })
    // },
    getMoreAddress() {
      this.isMore = !this.isMore
    },
    // 查询开户信息列表
    getBankInfo() {
      fundAccountInfoListSettleBankByCmpId(
        { cmpId: this.orderDetails.goodsDetailInfoDto.cmpId }
        , res => {
          this.accountListData = [...res.data]
          this.activeSettleBankId = this.accountListData[0].settleBankId
          this.getPayRule()
        })
    },
    // 查询支付方式
    getPayRule() {
      tradePayRuleStyleQuery(this.activeSettleBankId, res => {
        this.payRuleListData = [...res.data]
        this.formData.tradePayRuleStyleID = this.payRuleListData && this.payRuleListData.length ? this.payRuleListData[0].id : ''
        this.changePayRule(this.payRuleListData && this.payRuleListData.length ? this.payRuleListData[0].id : '')
      })
    },
    // 获取当前选择支付方式
    changePayRule(val) {
      if (!val) return
      this.formData.tradePayRuleStyleID = val
      this.payCurrentStyle = this.payRuleListData && this.payRuleListData.length > 0 ? this.payRuleListData.find(item => item.id === val) : {}
    },
    // 配送方式
    getDelivery(val) {
      this.formData.buyerPickUp = 0
      this.formData.merchantDistribution = 0
      this.formData.transfer = 0
      switch (val) {
        case 'buyerPickUp':
          this.formData.buyerPickUp = 1
          break
        case 'merchantDistribution':
          this.formData.merchantDistribution = 1
          break
        default:
          this.formData.transfer = 1
          break
      }
    },
    // 提交订单
    submitForm() {
      if (!this.formData.tradePayRuleStyleID || this.formData.tradePayRuleStyleID.length === 0) return this.$message.warning('请选择支付方式')
      if (!this.formData.tradeReceivingAddressId || this.formData.tradeReceivingAddressId.length === 0) return this.$message.warning('请选择收货地址')
      tradeOrderSave(this.formData, res => {
        this.$message.success(res.msg || '订单提交成功')
        sessionStorage.orderData = JSON.stringify(res.data)
        this.$store.commit('getOrderPayStyle', res.data.payStyle)
        this.$router.push({
          path: '/orderManagement/orderPayment',
          query: { merchantDistribution: this.formData.merchantDistribution }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.orderManagement {
  width: $centerPlateWidth;
  margin: auto;
  // padding-top: 18px;
  background-color: #fff;
  .confirmOrderTitle{
    height: 76px;
    line-height: 76px;
    color: #333;
    font-size: 18px;
    padding-left: 24px;
  }
  .confirmOrder {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    background-color: #f9f9f9;
    padding: 40px 0 80px;
    .buttonCombination{
      width: 332px;
      display: flex;
      a,button{
        flex: 1;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $themeColors;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        border-color: $themeColors;
        border-radius: 0;
      }
      .back{
        background-color: #f9f9f9;
        color: $themeColors;
        img{
          height: 20px;
          width: 20px;
          margin-right: 4px;
        }
      }
    }
    .amount {
      height: 108px;
      padding: 18px 0 0;
      text-align: right;
      .payment{
        font-size: 16px;
        color: #666;
        .freight{
          margin-left: 14px;
        }
      }
      .price {
        color: $mainFont;
        font-size: 24px;
        padding-left: 34px;
        padding-bottom: 11px;
        span {
          display: inline-block;
          width: 84px;
          color: $fontColor1;
          font-weight: 500;
          font-size: 16px;
          color: #333;
          margin-left: 16px;
        }
      }
      p:last-of-type {
        padding-bottom: 0;
      }
      .price {
        font-size: 18px;
        font-weight: 800;
        color: $ornamentColor2;
      }
    }
  }
  .paymentInformation {
    padding: 0 24px;
    .lable {
      color: #666;
      padding-bottom: 13px;
      font-size: 14px;
      .item{
        position: relative;
        display: inline-block;
        font-style: normal;
        min-width: 118px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border: 1px solid #e7e7e7;
        color: $mainFont;
        font-size: 14px;
        margin-right: 8px;
        cursor: pointer;
        img{
          position: absolute;
          top: 0;
          right: 0;
          height: 20px;
          width: 32px;
        }
      }
      .active{
        border-color: $themeColors;
        img{
          display: block;
        }
      }
      span {
        display: inline-block;
        width: 128px;
        color: $mainFont;
      }
    }
    .lable:last-of-type {
      padding-bottom: 0;
    }
    .price {
      color: $ornamentColor2;
      padding-bottom: 20px;
    }
    .title {
      color: $mainFont;
      font-size: 18px;
      font-weight: 500;
      height: 64px;
      line-height: 64px;
    }
  }
  .marginTop {
    margin: 20px 0 24px;
  }
  .merchantAccount {
    margin: 20px 0;
    padding: 17px 24px 0;
    .bankList {
      // padding: 0 46px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .bank {
        position: relative;
        display: flex;
        align-items: center;
        height: 72px;
        padding: 0 24px;
        box-sizing: border-box;
        border: 1px solid #fff;
        cursor: pointer;
        margin-right: 16px;
        margin-bottom: 18px;
        background-color: rgba(0,0,0,0.02);
        min-width: 196px;
        .checked{
          position: absolute;
          top: 0;
          right: 0;
          height: 20px;
          width: 32px;
        }
        p {
          color: $mainFont;
          font-size: 16px;
          font-weight: 800;
        }
        .bankImg {
          margin-right: 12px;
          height: 40px;
          width: 40px;
        }
      }
      .bank:nth-of-type(-n + 4) {
        margin-top: 0;
      }
      .active,
      .bank:hover {
        border: 1px solid $themeColors;
      }
    }
    .account {
      color: $mainFont;
      font-size: 18px;
      font-weight: 500;
      padding-bottom: 27px;
    }
  }

  .orderGoods {
    .commodityInformationBox {
      .commodityInformation {
        display: flex;
        align-items: center;
        padding: 16px 24px;
        font-size: 12px;
        color: $mainFont;
        border-bottom: 1px solid $borderColor3;
        .total {
          color: $themeColors;
        }
        .price {
          width: 170.5px;
        }
        .weight {
          width: 109.5px;
        }
        .detail {
          display: flex;
          align-items: left;
          justify-content: space-between;
          flex-direction: column;
          height: 76px;
          width: 318.5px;
          color: #666;
        }
        .title {
          width: 300px;
          height: 80px;
          font-weight: 700;
          color: $mainFont;
          padding-right: 63.5px;
          span{
            display: inline-block;
            height: 24px;
            line-height: 24px;
            padding: 0 10px;
            margin-top: 6px;
            color: $themeColors;
            font-size: 14px;
            background-color: rgba(211,30,14,0.08);
          }
        }

        .commodityImage {
          height: 80px;
          width: 80px;
          margin-right: 16px;
        }
      }
      .commodityInformation:last-of-type {
        border-bottom: 0;
      }
    }
    .companyName {
      display: flex;
      align-items: center;
      margin: 16px 24px 0;
      color: $mainFont;
      font-size: 14px;
      padding: 10px 0;
      border-bottom: 1px solid #e7e7e7;
      img{
        height: 16px;
        width: 16px;
        margin-right: 4px;
      }
    }
  }

  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    border: 1px solid #e7e7e7;
    background-color: rgba(0, 0, 0, 0.02);
    box-sizing: border-box;
    color: #999;
    font-size: 14px;
    margin: 0 24px;
    padding: 0 24px;
    .weight {
      width: 100px;
    }
    .unitPrice {
      width: 176px;
    }
    .specification{
      width: 287px;
    }

    .merchandiseNews {
      width: 450px;
    }
  }

  .consigneeInformation {
    border: 1px solid $borderColor3;

    .el-radio {
      margin-right: 4px;

      /deep/.el-radio__label {
        display: none;
      }
    }

    .moreAddress {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: $fontColor1;
      cursor: pointer;
      margin-left: 40px;

      i {
        transition: all 0.5s;
        margin-left: 7px;
        color: $fontColor1;
      }
    }
    .noAddress{
      padding:  0 0 10px 40px;
      font-size: 14px;
      color: $mainFont;
    }
    .addAddress{
      cursor: pointer;
      padding: 0 0 8px 40px;
      font-size: 12px;
      color: $ornamentColor;
    }

    .active {
      color: $mainFont;

      i {
        color: $mainFont;
        transform: rotate(180deg);
      }
    }
    .more-addressList {
      height: 40px;
      overflow: hidden;
    }

    .addressList {
      padding: 0 33px;
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: $mainFont;

      .edit {
        color: $ornamentColor;
      }

      .default {
        height: 25px;
        line-height: 25px;
        cursor: pointer;
        width: 71px;
        text-align: center;
        color: #fff;
        display: inline-block;
        background-color: #7f7f7f;
        margin-right: 16px;
      }

      .phone {
        margin: 0 12px 0 24px;
      }

      .contactInformation {
        max-width: 600px;
      }

      .font {
        color: $themeColors;
        margin-right: 16px;
      }

      .addressIcon {
        height: 25px;
        width: 25px;
        margin-right: 6px;
      }
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 17px 20px;
      font-size: 14px;
      font-weight: 900;

      a {
        color: $ornamentColor;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .address-manage {
    color: #2680eb;
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;
  }
}
.contact-buyer {
  display: flex!important;
  align-items: center;
  justify-content: center;
  width: 74px !important;
  font-size: 12px;
  background-color: rgba(211,30,14,0.08);
  color: $themeColors!important;
  height: 22px;
  line-height: 22px;
  padding: 0 7px;
  cursor: pointer;
  img{
    height: 16px;
    width: 16px;
    margin-right: 2px;
  }
}
.freight {
  width: auto !important;
}
</style>

<template>
  <div>
    <!-- 收货人信息 -->
    <div class="consigneeInformation">
      <h3 class="title">
        收货人信息
        <div class="address-manage" @click="clickAddressManage">
          新增收货地址
        </div>
      </h3>
      <!-- 选择哪个地址 -->
      <el-radio-group v-if="addressList.length > 0" v-model="tradeReceivingAddressId" :class="isMore ? '' : 'more-addressList'" @change="changeGroup">
        <div v-for="item in addressList" :key="item.id" class="addressList">
          <div class="left">
            <span :class="['font',tradeReceivingAddressId===item.id?'active':'']" @click="tradeReceivingAddressId=item.id">{{ item.receivePerson }}
              <img v-if="tradeReceivingAddressId===item.id" src="@/assets/img/checked.png" alt="icon">
            </span>
            <span class="name">
              {{ item.receivePerson }}
            </span>
            <el-radio :label="item.id">
              .
            </el-radio>
            <div class="contactInformation elp-1">
              {{
                `${item.placeProvince}${item.placeCity}${item.placeTown}${item.placeDetailAddr}`
              }}
            </div>
            <span class="phone">{{ item.receivePhone }}</span>
            <span v-if="item.defaultAddressFlag" class="default">默认地址</span>
          </div>
          <div class="right">
            <span
              v-if="!item.defaultAddressFlag"
              class="address-manage"
              @click="setAddressManage(item, 'default')"
            >设为默认地址</span>&nbsp;&nbsp;
            <span
              class="address-manage"
              @click="clickAddressManage(item, 'edit')"
            >编辑</span>&nbsp;&nbsp;
            <span
              class="address-manage"
              @click="setAddressManage(item, 'delete')"
            >删除</span>
          </div>
        </div>
      </el-radio-group>
      <!-- 点击更多地址的东西 -->
      <p
        v-if="addressList.length > 1"
        :class="['moreAddress', isMore ? 'active' : '']"
        @click="getMoreAddress"
      >
        {{ !isMore?'更多地址':'收起' }}<i class="el-icon-arrow-down" />
      </p>
      <p v-if="addressList.length === 0" class="noAddress">
        暂无地址
      </p>
      <p v-if="addressList.length === 0" class="addAddress" @click="clickAddressManage">
        新增收货地址
      </p>
    </div>
    <el-dialog
      :title="addressTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="ruleFormRef" :model="formData" label-width="100px" label-position="left">
        <el-form-item label="所在地区" prop="receivingArea" :rules="rules.selectiveType">
          <el-cascader
            v-model="formData.receivingArea"
            :props="{ label: 'name', value: 'name', children: 'children' }"
            :options="allCityOption"
            @change="getReceivingArea"
          />
        </el-form-item>
        <el-form-item label="详细地址" prop="placeDetailAddr" :rules="[...rules.message, { min: 1, max: 50, message: '长度为1~50个字符', trigger: 'blur' }]">
          <el-input v-model="formData.placeDetailAddr" type="textarea" placeholder="填输入详细地址" />
        </el-form-item>
        <el-form-item label="收货联系人" prop="receivePerson" :rules="[...rules.message, { min: 2, max: 20, message: '长度为2~20个字符', trigger: 'blur' }]">
          <el-input v-model="formData.receivePerson" />
        </el-form-item>
        <el-form-item label="手机号码" prop="receivePhone" :rules="rules.phone">
          <el-input v-model="formData.receivePhone" placeholder="填写手机号" />
        </el-form-item>
        <el-form-item label="" prop="defaultAddressFlag">
          <el-checkbox v-model="defaultAddressFlag">
            设置为默认地址
          </el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addressSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSelectAreaTree } from '@/http/common'
import { rules } from '@/unit/matchFormRegex'
import {
  tradeReceivingAddressList,
  tradeReceivingAddressSave,
  tradeReceivingAddressUpdate,
  tradeReceivingAddressRemove,
  tradeReceivingAddressSetting
} from '@/http/address'

export default {
  props: {
    // dialogVisible: {
    //   type: Boolean,
    //   default: false
    // },
    // tradeReceivingAddressId: {
    //   type: [String, Number, Boolean],
    //   default: null
    // }
  },
  data() {
    return {
      rules,
      // 地区
      allCityOption: [],
      defaultAddressFlag: false,
      formData: {
        cmpId: JSON.parse(localStorage.getItem(`mallFrontDeskUserInfo${this.$store.state.userCmpRole}`)).cmpId,
        receivingArea: []
      },
      addressList: [],
      // 地址展开
      isMore: false,
      dialogVisible: false,
      addressTitle: '新增收货地址',
      tradeReceivingAddressId: null
    }
  },
  mounted() {
    this.getAddressList()
    getSelectAreaTree('00', res => {
      this.allCityOption = [...res.data]
    })
  },
  methods: {
    changeGroup(radio) {
      this.$emit('changeGroup', radio)
    },
    // 新增、编辑收货地址
    clickAddressManage(item, type) {
      this.dialogVisible = true
      this.addressTitle = type ? '编辑收货地址' : '新增收货地址'
      if (!type) return
      this.formData = JSON.parse(JSON.stringify(item))
      this.formData.receivingArea = [item.placeProvince, item.placeCity, item.placeTown]
      this.defaultAddressFlag = item.defaultAddressFlag
    },
    // 获取所有收货地址
    getAddressList() {
      tradeReceivingAddressList((res) => {
        this.addressList = [...res.data]
        const defaultAddress = res.data && res.data.length > 0 ? res.data.find((item) => item.defaultAddressFlag === true) : {}
        if (defaultAddress) {
          this.tradeReceivingAddressId = defaultAddress.id || ''
          this.changeGroup(this.tradeReceivingAddressId)
          return
        }
        if (this.addressList.length > 0) {
          this.tradeReceivingAddressId = this.addressList[0].id
          this.changeGroup(this.tradeReceivingAddressId)
        }
      })
    },
    // 地址管理 设置默认/删除
    async setAddressManage({ id }, type) {
      let tipText = ''
      let api = ''
      if (type === 'delete') {
        tipText = '确认要删除该收货地址吗？'
        api = tradeReceivingAddressRemove
      }
      if (type === 'default') {
        tipText = '确认要设为默认收货地址吗？'
        api = tradeReceivingAddressSetting
      }
      const confirm = await this.$confirm(tipText, '提示', {
        type: 'warning'
      }).catch((err) => err)
      if (confirm !== 'confirm') return
      api(id, (res) => {
        this.$message.success(res.msg || '成功')
        this.getAddressList()
      })
    },
    getMoreAddress() {
      this.isMore = !this.isMore
    },
    async addressSave() {
      const valid = await this.$refs.ruleFormRef.validate().catch(() => false)
      if (!valid) return
      if (this.defaultAddressFlag === true) {
        this.formData.defaultAddressFlag = 1
      } else {
        this.formData.defaultAddressFlag = 0
      }
      let api = tradeReceivingAddressSave
      if (this.formData.id) {
        api = tradeReceivingAddressUpdate
      }
      api(this.formData, res => {
        this.$message.success(res.msg || '保存成功')
        this.dialogVisible = false
        this.getAddressList()
        // this.$emit('update:dialogVisible', false)
        // this.$emit('getAddressList')
      })
    },
    // 获取地区
    getReceivingArea(val) {
      this.formData.placeProvince = val[0]
      this.formData.placeCity = val[1]
      this.formData.placeTown = val[2]
    },
    handleClose() {
      this.dialogVisible = false
      // this.$emit('update:dialogVisible', false)
      this.formData = {
        cmpId: JSON.parse(localStorage.getItem(`mallFrontDeskUserInfo${this.$store.state.userCmpRole}`)).cmpId,
        receivingArea: []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.consigneeInformation {
    .el-radio {
      display: none;
    }

    .moreAddress {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: $fontColor1;
      cursor: pointer;
      margin-left: 24px;
      height: 24px;
      line-height: 24px;
      padding: 16px 0 12px;

      i {
        transition: all 0.5s;
        margin-left: 7px;
        color: $fontColor1;
      }
    }
    .noAddress{
      padding:  0 0 10px 40px;
      font-size: 14px;
      color: $mainFont;
    }
    .addAddress{
      cursor: pointer;
      padding: 0 0 8px 40px;
      font-size: 12px;
      color: $ornamentColor;
    }

    .active {
      color: $mainFont;

      i {
        color: $mainFont;
        transform: rotate(180deg);
      }
    }
    .more-addressList {
      height: 48px;
      overflow: hidden;
    }
    .el-radio-group{
      width: 100%;
    }

    .addressList {
      padding: 0 24px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: $mainFont;
      .left,.right{
        display: flex;
        align-items: center;
      }
      .right{
        display: none;
        flex: 0 0 190px;
        justify-content: flex-end;
        color: #333;
        font-size: 14px;
      }
      .left{
        flex: 1;
        width: calc(100% - 190px);
        justify-content: flex-start;
      }

      .edit {
        color: $ornamentColor;
      }

      .default {
        height: 21px;
        line-height: 21px;
        font-size: 12px;
        flex: 0 0 64px;
        text-align: center;
        color: #666;
        display: inline-block;
        margin-right: 16px;
        border: 1px solid rgba(231,231,231,1);
        background: rgba(245,245,245,1);
      }

      .phone {
        margin: 0 16px;
      }

      .font {
        position: relative;
        display: inline-block;
        flex: 0 0 120px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border: 1px solid #e7e7e7;
        color: #666;
        margin-right: 24px;
        box-sizing: border-box;
        cursor: pointer;
        img{
          position: absolute;
          top: 0;
          right: 0;
          width: 32px;
          height: 20px;
        }
      }
      .active{
        border-color: $themeColors;
        background: rgba(211,30,14,0.02);
      }
      .name{
        flex: 0 0 auto;
        margin-right: 16px;
        color: #333;
      }
      .addressIcon {
        height: 25px;
        width: 25px;
        margin-right: 6px;
      }
    }
    .addressList:hover{
       background-color: rgba(211,30,14,0.04);
       cursor: pointer;
       .right{
        display: flex;
       }
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      height: 65px;
      font-size: 18px;
      font-weight: 900;
      color: #333;
      >div {
        color: $themeColors;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .address-manage {
    cursor: pointer;
  }
</style>
